import service from "@/api/service";
import { CouponReadGetRequest } from "@/api/analysis/coupon-read/request";
import { CouponReadGetResponse } from "@/api/analysis/coupon-read/response";

/**
 * クーポン閲覧ステータスAPIをコールします。
 *
 * @param getRequest クーポン閲覧ステータスAPIのリクエストボディ
 * @return CouponReadGetResponse
 */
export async function get(getRequest: CouponReadGetRequest) {
  const response = await service.post("/analysis-coupon-read", getRequest);
  return response.data as CouponReadGetResponse;
}
