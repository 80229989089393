import { Component, Mixins, Vue, Watch } from "vue-property-decorator";
import AnalysisBase from "@/models/analysis/analysis-base";
import UlContentHeader from "@/components/UlContentHeader.vue";
import UIDatePicker from "@/components/UIDatePicker.vue";
import Flash, { ErrorAlert } from "@/store/common/flash";
import ShopGet from "@/store/shop/get";
import CouponChart from "@/views/analysis/CouponChart.vue";
import Admin from "@/store/admin/admin";
import UIDataTable from "@/components/UIDataTable.vue";
import { TableOptions } from "@/api/request";
import Coupon from "@/components/dashboard/Coupon.vue";
import { CouponReadGetRequest } from "@/api/analysis/coupon-read/request";
import CouponListGet from "@/store/analysis/shop-coupon-list/get";
import CouponReadGet from "@/store/analysis/coupon-read/get";
import CouponUseListGet from "@/store/analysis/coupon-list/get";
import { GetRequest as CouponGetRequest } from "@/api/coupon-list/request";
import { CouponListGetRequest } from "@/api/analysis/coupon-list/request";
import * as CouponUseListCSVAPI from "@/api/analysis/coupon-use-csv";

export interface CouponReadInputOptions {
  selectCoupon: { id: number; title: string } | null;
  selectShop: { id: string; name: string } | null;
}

export interface CouponUseListInputOptions extends CouponReadInputOptions {
  startDate: string;
  endDate: string;
}

@Component({
  components: {
    UlContentHeader,
    UIDatePicker,
    CouponChart,
    UIDataTable
  }
})
export default class AnalysisCoupon extends Mixins(AnalysisBase) {
  @Watch("couponReadInputOptions", { deep: true })
  couponReadInputOptionsDidChange(inputOptions: CouponReadInputOptions) {
    this.couponUseListInputOptions.selectCoupon = inputOptions.selectCoupon;
    this.couponUseListInputOptions.selectShop = inputOptions.selectShop;
  }

  @Watch("couponReadInputOptions.selectShop")
  async selectShopDidChange(selectShop: { id: string; name: string } | null) {
    this.couponReadInputOptions.selectCoupon = null;
    this.couponUseListInputOptions.selectCoupon = null;
    if (selectShop) {
      await this.fetchCouponList();
    } else {
      await CouponListGet.clearResponse();
    }
  }

  // ------------
  // 固定値
  // ------------
  // タイトル
  headingMain = "クーポン";
  headingSub = "Coupon";
  breadCrumbs = [{ text: "クーポン", disabled: true }];

  // ------------
  // 変動値
  // ------------
  // ローディングステータス
  isLoadedShopData = false;
  isLoadedCouponListData = false;
  isLoadedCouponReadData = false;
  isLoadingCouponUseListData = false;

  // DatePickerの表示フラグ
  startDateModal = null;
  endDateModal = null;

  // 検索入力オプション
  couponReadInputOptions: CouponReadInputOptions = {
    selectCoupon: null,
    selectShop: null
  };

  couponUseListInputOptions: CouponUseListInputOptions = {
    selectCoupon: null,
    selectShop: null,
    startDate: this.initDates[0],
    endDate: this.initDates[1]
  };

  // テーブルヘッダ（UIDataTableコンポーネントに渡す）
  tableHeaders = [
    { text: "状態", value: "publicStatus", sortable: false },
    { text: "クーポンタイトル", value: "title", sortable: false },
    { text: "クーポンタイプ", value: "type", sortable: false },
    { text: "クーポン有効期限", value: "expirationDate", sortable: false },
    { text: "クーポン利用回数", value: "useNum", sortable: false },
    {
      text: "クーポン新規閲覧者数（アプリ合計）",
      value: "newReadNum",
      sortable: false
    }
  ];

  // テーブル検索オプション（UIDataTableコンポーネントに渡す）
  tableOptions = {} as TableOptions;

  totalCountOnDrillDowned = 0;
  totalCountName = "総ユーザ発行枚数";

  get startDateForView() {
    return this.replaceHyphenToSlash(this.couponUseListInputOptions.startDate);
  }
  set startDateForView(date) {
    this.couponUseListInputOptions.startDate = this.replaceSlashToHyphen(date);
  }

  get endDateForView() {
    return this.replaceHyphenToSlash(this.couponUseListInputOptions.endDate);
  }
  set endDateForView(date) {
    this.couponUseListInputOptions.endDate = this.replaceSlashToHyphen(date);
  }

  get couponItems() {
    return CouponListGet.getItems;
  }

  get shopItems() {
    return ShopGet.getItems;
  }

  get initDates() {
    const date = new Date();
    date.setDate(date.getDate() - 7);
    const firstDate = new Date(date);
    const lastDate = new Date();
    return [this.stringFromDate(firstDate), this.stringFromDate(lastDate)];
  }

  /**
   * createdライフサイクルフック
   */
  async created() {
    if (this.editFocused || !this.isNeededActiveOnDashBoard) {
      return false;
    }

    await this.fetchShopList();
    if (!Admin.isAdmin) {
      this.couponUseListInputOptions.selectShop = ShopGet.getItems[0];
      this.couponReadInputOptions.selectShop = ShopGet.getItems[0];
      await this.fetchCouponList();
      this.couponUseListInputOptions.selectCoupon = CouponListGet.getItems[0];
      this.couponReadInputOptions.selectCoupon = CouponListGet.getItems[0];
      await this.fetchCouponRead();
      await this.fetchCouponUseList();
    }
  }

  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {
    await ShopGet.clearResponse();
    await CouponReadGet.clearResponse();
    await CouponUseListGet.clearResponse();
  }

  /**
   * 総件数
   */
  get totalCount() {
    return this.totalCountOnDrillDowned > 0
      ? this.totalCountOnDrillDowned
      : CouponReadGet.getTotalCount;
  }

  get couponReadData() {
    return CouponReadGet.getData;
  }

  /**
   * テーブルに表示するアイテムリスト（UIDataTableコンポーネントに渡す）
   */
  get tableItems() {
    return CouponUseListGet.getItems;
  }

  /**
   * 総件数（UIDataTableコンポーネントに渡す）
   */
  get listTotalCount() {
    return CouponUseListGet.getItems.length;
  }

  async fetchCouponReadAndCouponUseList() {
    await this.fetchCouponList();
    await this.fetchCouponRead();
    await this.fetchCouponUseList();
  }

  /**
   * 店舗情報の一覧を取得する処理
   */
  async fetchShopList(): Promise<boolean> {
    this.isLoadedShopData = false;
    await ShopGet.get();
    if (!ShopGet.isSuccess) {
      await Flash.setErrorNow({
        message: ShopGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoadedShopData = true;
    return ShopGet.isSuccess;
  }

  /**
   * クーポンの一覧を取得する処理
   */
  async fetchCouponList(): Promise<boolean> {
    this.isLoadedCouponListData = false;
    await CouponListGet.get({
      id:
        this.couponReadInputOptions.selectShop != null
          ? Number(this.couponReadInputOptions.selectShop.id)
          : null
    } as CouponGetRequest);
    if (!CouponListGet.isSuccess) {
      await Flash.setErrorNow({
        message: CouponListGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoadedCouponListData = true;

    return CouponListGet.isSuccess;
  }

  /**
   * グラフデータを取得する処理
   */
  async fetchCouponRead(): Promise<boolean> {
    this.isLoadedCouponReadData = false;
    await Flash.clear();
    const couponReadGetRequest: CouponReadGetRequest = {
      couponId:
        this.couponReadInputOptions.selectCoupon != null
          ? Number(this.couponReadInputOptions.selectCoupon.id)
          : null,
      searchShopId:
        this.couponReadInputOptions.selectShop != null
          ? Number(this.couponReadInputOptions.selectShop.id)
          : null
    };
    await CouponReadGet.get(couponReadGetRequest);
    if (!CouponReadGet.isSuccess) {
      await Flash.setErrorNow({
        message: CouponReadGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoadedCouponReadData = true;
    return CouponReadGet.isSuccess;
  }

  /**
   * ページング変更コールバック
   *
   * @param tableOptions TableOptions
   */
  async tableChangeCallback(tableOptions: TableOptions) {
    // 子コンポーネントへpagingOptionsの変更が通知される
    this.tableOptions = tableOptions;
    await this.fetchCouponUseList();
  }

  /**
   * 検索処理
   */
  async fetchCouponUseList() {
    this.isLoadingCouponUseListData = true;
    const request = this.createCouponUseListGetRequest();

    await CouponUseListGet.get(request);
    if (!CouponUseListGet.isSuccess) {
      await Flash.setErrorNow({
        message: CouponUseListGet.getMessage,
        showReloadButton: true
      } as ErrorAlert);
    }
    this.isLoadingCouponUseListData = false;
  }

  /**
   * dataから検索用のリクエストを作成する
   */
  private createCouponUseListGetRequest(): CouponListGetRequest {
    const request = this.tableOptions as CouponListGetRequest;
    request.searchShopId =
      this.couponUseListInputOptions.selectShop != null
        ? Number(this.couponUseListInputOptions.selectShop.id)
        : null;
    request.couponId =
      this.couponUseListInputOptions.selectCoupon != null
        ? Number(this.couponUseListInputOptions.selectCoupon.id)
        : null;
    request.startDate = this.startDateForView;
    request.endDate = this.endDateForView;
    return request;
  }

  async fetchCouponUseListCSV() {
    const couponUseListCSVGetResponse = await CouponUseListCSVAPI.get(
      this.createCouponUseListGetRequest()
    );

    if (
      couponUseListCSVGetResponse &&
      couponUseListCSVGetResponse.statusCd !== 200
    ) {
      await Flash.setErrorNow({
        message: couponUseListCSVGetResponse.message,
        showReloadButton: true
      } as ErrorAlert);
    }
    this.saveCSV(
      couponUseListCSVGetResponse.results.csvString,
      couponUseListCSVGetResponse.results.fileName
    );
  }

  updateTotalCountOnDrillDowned(object: {
    totalCount: number;
    name: string;
  }) {
    this.totalCountOnDrillDowned = object.totalCount;
    this.totalCountName = object.name;
  }

}
