import service from "@/api/service";
import { CouponProfileGetRequest } from "@/api/analysis/coupon-profile/request";
import { CouponProfileGetResponse } from "@/api/analysis/coupon-profile/response";

/**
 * クーポンプロフィール属性APIをコールします。
 *
 * @param getRequest クーポンプロフィール属性APIのリクエストボディ
 * @return CouponProfileGetResponse
 */
export async function get(getRequest: CouponProfileGetRequest) {
  const response = await service.post("/analysis-coupon-profile", getRequest);
  return response.data as CouponProfileGetResponse;
}
