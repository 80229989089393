import service from "@/api/service";
import { CouponReadGetRequest } from "@/api/analysis/coupon-read/request";
import { CouponReadGetResponse } from "@/api/analysis/coupon-read/response";
import { CouponUseGetRequest } from "@/api/analysis/coupon-use/request";

/**
 * クーポン利用ステータスAPIをコールします。
 *
 * @param getRequest クーポン利用ステータスAPIのリクエストボディ
 * @return CouponReadGetResponse
 */
export async function get(getRequest: CouponUseGetRequest) {
  const response = await service.post("/analysis-coupon-use", getRequest);
  return response.data as CouponReadGetResponse;
}
