import {
  getModule,
  Module,
  MutationAction,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import * as CouponListAPI from "@/api/analysis/coupon-list";
import { isSuccess } from "@/api/response";
import { CouponListGetRequest } from "@/api/analysis/coupon-list/request";
import { CouponListGetResponse } from "@/api/analysis/coupon-list/response";

const MODULE_NAME = "analysis/coupon-list/get";

/**
 *  クーポン利用リスト取得API（/analysis-coupon-list）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Get extends VuexModule {
  // state
  getRequest: CouponListGetRequest = {} as CouponListGetRequest;
  getResponse: CouponListGetResponse = {} as CouponListGetResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.getResponse);
  }

  get getMessage() {
    return this.getResponse.message;
  }

  get getItems() {
    if (this.getResponse.results) {
      return this.getResponse.results.items || [];
    } else {
      return [];
    }
  }

  get getGetRequest() {
    return this.getRequest;
  }

  // MutationActions
  @MutationAction
  async get(getRequest: CouponListGetRequest) {
    const getResponse = await CouponListAPI.get(getRequest);
    return {
      getResponse
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      getResponse: {} as CouponListGetResponse
    };
  }
}

export default getModule(Get);
