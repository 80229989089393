import {
  getModule,
  Module,
  MutationAction,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import * as AnalysisCouponUseAPI from "@/api/analysis/coupon-use";
import { isSuccess } from "@/api/response";
import { CouponUseGetRequest } from "@/api/analysis/coupon-use/request";
import { CouponUseGetResponse } from "@/api/analysis/coupon-use/response";
import { AnalysisGraphData } from "@/api/analysis/common/response";

const MODULE_NAME = "analysis/coupon-use/get";

/**
 *  クーポン利用ステータスAPI（/analysis-coupon-use）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Get extends VuexModule {
  // state
  getRequest: CouponUseGetRequest = {} as CouponUseGetRequest;
  getResponse: CouponUseGetResponse = {} as CouponUseGetResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.getResponse);
  }

  get getMessage() {
    return this.getResponse.message;
  }

  get getData() {
    if (this.getResponse.results) {
      return this.getResponse.results.data;
    } else {
      return {} as AnalysisGraphData;
    }
  }

  get getTotalCount() {
    if (this.getResponse.results) {
      return this.getResponse.results.totalCount || 0;
    } else {
      return 0;
    }
  }

  get getGetRequest() {
    return this.getRequest;
  }

  // MutationActions
  @MutationAction
  async get(getRequest: CouponUseGetRequest) {
    const getResponse = await AnalysisCouponUseAPI.get(getRequest);
    return {
      getResponse,
      getRequest
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      getResponse: {} as CouponUseGetResponse
    };
  }
}

export default getModule(Get);
